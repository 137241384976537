export const environment = {
    production: false,
    basePath: 'https://dev.pace.nbcuni.com/api/',
    campaignBasePath: 'https://dev.pace.nbcuni.com/api/',
    adminBasePath: 'https://dev.pace.nbcuni.com/api/',
    userBasePath: 'https://dev.pace.nbcuni.com/api/',
    invoiceBasePath: 'https://dev.pace.nbcuni.com/api/',
    reportsBasePath: 'http://compassreportsdev.inbcu.com/reports/report/PACE_DEV/',
    environmentName: 'dev',
    name: 'DEV',
    clientId: 'NBC_Media_Stage',
    sapURL: 'https://brimdevapi.test.apimanagement.eu10.hana.ondemand.com:443/fin/account_masterdata/validation?COMPANY_CODE=B0A1',
    sapApiKey: 'RTfjZuU8aanOeE2FXrB7nS6nFAc1rkJG',
    authURL: 'fss.stg.inbcu.com/fss',
    appURL: 'https://dev.pace.nbcuni.com',
    logoutURL: 'http://login.stg.inbcu.com/ssologin/logoff.jsp?referrer=',
    identityPoolId: '',
    scope: 'ExtMFAEnabled',
    powerBiUrl: 'https://app.powerbi.com/groups/5e3627ef-0c7f-4f77-ba20-08867a250c00/list?experience=power-bi',
};
